import React, { Fragment, useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import Footer from '../containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PersonaSection from '../containers/Saas/personaSection';
import CallToActionBottom from '../containers/Saas/TrialSection';
import PersonaBanner from '../containers/Saas/BannerPersona';
import SalesRepSection from '../containers/Saas/SalesRepSection';
import SalesManagerSection from '../containers/Saas/SalesManagerSection';
import AccountExecutiveSection from '../containers/Saas/AccountExecutiveSection';
import ModalForm from '../containers/Saas/contactModal';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default () => {
  if (typeof window === `undefined`) {
    return <></>;
  }

  const [openDemoModal, setOpenDemoModal] = useState(false);

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          hash = hash.slice(1, hash.length);
          var element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1500);
      }
    }
  }, []);

  function handleGoToElement(hash) {
    var element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function handleOpenDemoModal() {
    setOpenDemoModal(true);
  }
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="The leading platform for sales teams"
          description="The innovative sales development platform for sales teams to engage with prospects in a more efficient and organised way!"
        />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <PersonaBanner
              handleOpenDemoModal={handleOpenDemoModal}
              handleGoToElement={handleGoToElement}
            />
            <SalesRepSection handleOpenDemoModal={handleOpenDemoModal} />
            <SalesManagerSection handleOpenDemoModal={handleOpenDemoModal} />
            <AccountExecutiveSection
              handleOpenDemoModal={handleOpenDemoModal}
            />
            <CallToActionBottom />
            <Footer />
            <ModalForm open={openDemoModal} setOpen={setOpenDemoModal} />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
