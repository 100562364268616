import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RootManager } from './salesManager.style';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';

const data = [
  {
    title: 'Real-time data analytics on team performance',
  },
  {
    title: 'Analyse campaign performances to improve team results',
  },
  {
    title: 'Full data ownership options and extensive filters',
  },
];

const PersonaSection = ({
  col,
  title,
  description,
  textArea,
  featureTitle,
}) => {
  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        hash = hash.slice(1, hash.length);
        var element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, []);

  return (
    <>
      <RootManager id="salesManager">
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={<Heading content="Sales Manager" {...title} />}
              description={
                <Text
                  content="Measure performances of your team on different levels, have insights in every part of your reps’ funnel and get all the necessities you need to coach your team."
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            {data.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className="flaticon-next" />}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
          </Box>
        </Box>
      </RootManager>
    </>
  );
};

PersonaSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

PersonaSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
    paddingLeft: [0, '10rem'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
    as: 'h1',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
};

export default PersonaSection;
