import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Link from 'common/src/components/Link';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BlogSectionWrapper, { ContainerWrapper } from './bannerPersona.style';
import Grid from '@material-ui/core/Grid';

const BlogSection = ({ row, blogTitle, blogMeta, handleGoToElement }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        blog {
          date
          postLink
          title
          id
          position
          thumbnail_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BlogSectionWrapper id="personaSection">
      <ContainerWrapper>
        {/* <Box {...sectionHeader}>
          <Text content="PORTFOLIO" {...sectionSubTitle} />
          <Heading
            content="Meet our work experience from customers"
            {...sectionTitle}
          />
        </Box> */}
        <Box className="row" {...row}>
          <Grid container>
            {Data.saasJson.blog.map((post, index) => (
              <Grid item xs={12} md={4} style={{ padding: '0 5%' }}>
                <FeatureBlock
                  onClick={() => handleGoToElement(post.postLink)}
                  key={`post_key-${index}`}
                  id={`post_id-${post.id}`}
                  className="blog__post"
                  icon={
                    <img
                      src={post.thumbnail_url.childImageSharp.fluid.src}
                      alt={`Blog Image ${post.id}`}
                      objectFit="cover"
                      className="blog__image"
                      object-position={`${post.position || 'center'}`}
                      style={{
                        objectPosition: post.position,
                      }}
                    />
                  }
                  title={
                    <Link href={post.postLink} {...blogTitle}>
                      {post.title}
                    </Link>
                  }
                  description={<Text content={post.date} {...blogMeta} />}
                  style={{ width: '100%', cursor: 'pointer' }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </ContainerWrapper>
    </BlogSectionWrapper>
  );
};

// BlogSection style props
BlogSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  blogTitle: PropTypes.object,
  blogMeta: PropTypes.object,
};

// BlogSection default style
BlogSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // Blog post row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-12px',
    mr: '-12px',
  },
  // Blog post title default style
  blogTitle: {
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#ffffff',
    lineHeight: '1.5',
    mb: '10px',
    letterSpacing: '-0.020em',
  },
  // Blog post description default style
  blogMeta: {
    fontSize: '16px',
    lineHeight: '1',
    color: 'rgba(255, 255, 255, 0.5)',
    mb: 0,
  },
};

export default BlogSection;
