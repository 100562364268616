import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import AboutUsSectionWrapper from './salesRep.style';
import Rep1 from 'common/src/assets/image/saas/rep_1.jpg';
import Rep2 from 'common/src/assets/image/saas/group_1.jpg';
import Rep3 from 'common/src/assets/image/saas/rep_3.jpg';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
  handleOpenDemoModal,
}) => {
  const data = [
    {
      title:
        'Stay focussed on closing deals while SalesOcean helps you through the process',
    },
    {
      title: 'Clear overview of which account to work on per week/month',
    },
    {
      title:
        'Build a personalized automated sequence strategies to engage better and faster',
    },
  ];
  return (
    <AboutUsSectionWrapper id="salesRepresentative">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <img src={Rep1} objectFit="contain" alt="Feature Image 1" />
              </Fade>
              <Fade left delay={60}>
                <img src={Rep2} objectFit="contain" alt="Feature img 3" />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <img src={Rep3} objectFit="contain" alt="Feature Image 2" />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={<Heading content="Sales Representative" {...title} />}
              description={
                <Text
                  content="SalesOcean provides intelligence and automation solutions to make sure you always achieve your goals. Engage more prospects, build bigger pipelines and work on every opportunity you have."
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            {data.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className="flaticon-next" />}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
            <Button
              title="SEE IN ACTION"
              variant="outlined"
              onClick={handleOpenDemoModal}
              {...btnStyle}
            />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
    as: 'h1',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
};

export default AboutUsSection;
