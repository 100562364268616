import styled from 'styled-components';
import Manager from 'common/src/assets/image/saas/manager.png';
import Rep from 'common/src/assets/image/saas/rep_3.jpeg';
import SuccessManager from 'common/src/assets/image/saas/success_manager.jpeg';
import { themeGet } from 'styled-system';

export const RepRoot = styled.div`
  height: 50rem;
  width: 100%;
  background-image: url(${Rep});
  background-size: cover;
  margin-top: 116px;
  @media (max-width: 600px) {
    background-position-x: 20%;
  }
`;

export const RootManager = styled.div`
  padding: 80px 0 50px;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media (max-width: 990px) {
    padding: 60px 0 40px 0;
  }
  .col {
    align-self: center;
  }

  height: 50rem;
  width: 100%;
  background-image: url(${Manager});
  background-size: cover;
  @media (max-width: 1000px) {
    background-position-x: right;
  }
  @media (max-width: 450px) {
    background-position-x: 70%;
  }

  @media (max-width: 767px) {
    .col {
      font-weight: 600 !important;
      padding-top: 10rem;
    }
  }

  .feature__block {
    align-items: center;
    margin-bottom: 14px;
    .icon__wrapper {
      color: ${themeGet('colors.primary', '#10ac84')};
      margin-right: 10px;
    }
    .content__wrapper {
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .reusecore__button {
    margin-top: 36px;
    margin-left: 20px;

    transition: all 0.3s ease;
  }
`;

export const RootSuccessManager = styled.section`
  padding: 80px 0 50px;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 40px 0;
  }
  .col {
    align-self: center;
  }

  height: 50rem;
  width: 100%;
  background-image: url(${SuccessManager});
  background-size: cover;
  @media (max-width: 600px) {
    background-position-x: 10%;
  }
`;

export const TextPositionRight = styled.div`
  width: 35rem;
  float: right;
  margin-top: 10rem;
  margin-right: 7rem;
  @media (max-width: 1000px) {
    width: 80%;
    float: left;
    margin-top: 10rem;
    margin-left: 7rem;
  }
  @media (max-width: 600px) {
    margin-left: 8%;
  }
`;

export const TextPositionLeft = styled.div`
  width: 35rem;
  float: left;
  margin-top: 14rem;
  margin-left: 7rem;
  @media (max-width: 1000px) {
    width: 80%;
    float: left;
    margin-top: 10rem;
    margin-left: 7rem;
  }
  @media (max-width: 600px) {
    margin-left: 8%;
  }
`;

export const HeaderText = styled.p`
  font-size: 18px;
  color: white;
  line-height: 56px;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.15em;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 18px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const ContentText = styled.p`
  font-family: Verdana;
  color: #2a313c;
  font-size: 25px;
  @media (max-width: 1000px) {
    color: white;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;

export const PowerTitle = styled.h2`
  font-size: 50px;
  color: #2a313c;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
  margin-top: 0;
  @media (max-width: 1000px) {
    color: rgb(93, 118, 255);
  }
  @media (max-width: 600px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 32px;
  }
`;
